

















import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'ReportItem',
  props: {
    color: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    return {};
  },
});
