import colors from 'vuetify/lib/util/colors';

export const basicColors = [
  colors.pink.lighten1,
  colors.purple.lighten1,
  colors.blue.lighten1,
  colors.teal.lighten1,
  colors.lime.lighten1,
  colors.amber.lighten1,
  colors.deepOrange.lighten1,
  colors.red.lighten1,
  colors.deepPurple.lighten1,
  colors.lightBlue.lighten1,
  colors.green.lighten1,
  colors.orange.lighten1,
  colors.indigo.lighten1,
  colors.cyan.lighten1,
  colors.lightGreen.lighten1,
  colors.brown.lighten1,
  colors.blueGrey.lighten1,
];

export const pointColors = {
  red: colors.red.base,
  orange: colors.orange.base,
  green: colors.green.base,
  blue: colors.blue.base,
  grey: colors.grey.lighten1,
  lightgrey: colors.grey.lighten2,
};
