































































































































































import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import Chart from 'chart.js/auto';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import ReportItem from '@/components/ReportItem.vue';
import FcDateFilter from '@/components/FcDateFilter.vue';
import report from '@/composition/report';
import { pointColors, basicColors } from '@/util/chartColor';

export default defineComponent({
  name: 'ReportMembers',
  components: {
    FcRoleLoading,
    FcRoleDeny,
    ReportItem,
    FcDateFilter,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('report'));

    // データ取得
    report.getReportData();

    // 会員詳細
    const userDetailCounts = computed(() => {
      const activeUsers = report.activeUsers;
      if (!activeUsers) return null;
      return {
        hasSubscriptionPlans: report.hasSubscriptionPlans,
        subscriptionPlan: report.hasSubscriptionPlans
          ? activeUsers.filter((item) => item.subscriptionPlanIds.length).length.toLocaleString()
          : 0,
        freePlan: activeUsers
          .filter(
            (item) =>
              !item.isUncompletedSignup && !item.isFailedLatestSubscriptionPayment && !item.subscriptionPlanIds.length
          )
          .length.toLocaleString(),
        isFailedLatestSubscriptionPayment: activeUsers
          .filter((item) => !item.subscriptionPlanIds.length && item.isFailedLatestSubscriptionPayment)
          .length.toLocaleString(),
        isUncompletedSignup: activeUsers.filter((item) => item.isUncompletedSignup).length.toLocaleString(),
      };
    });

    // 会員数推移
    const userChangeFilterDates = ref<(number | null)[]>([null, null]);
    const userChangeCounts = computed(() => {
      const userCounts = report.userCounts;
      if (!userCounts.length) return null;

      const filteredData = report.getFilteredData(userCounts, userChangeFilterDates.value);
      if (!filteredData) return null;

      const filteredDataDates = filteredData.dates;
      const filteredDataItems = filteredData.items;

      return {
        data: filteredDataItems,
        displayDate: filteredDataDates,
        new: filteredDataItems.reduce((acc, current) => acc + current.counts.new, 0).toLocaleString(),
        leave: filteredDataItems.reduce((acc, current) => acc + current.counts.leave, 0).toLocaleString(),
      };
    });

    const changeCountsChartCanvas = ref<HTMLCanvasElement | null>(null);
    let changeCountsChart: any = null;
    const changeCountsChartHiddenIndexes = ref<number[]>([]); // 再レンダリング時に非表示にしておくデータのindexを保管
    const createChangeCountsChart = () => {
      if (!changeCountsChartCanvas.value || report.isLoading || !userChangeCounts.value) return;

      const labels = userChangeCounts.value.data.map((item) => item.displayDate);
      const data = userChangeCounts.value.data.map((item) => item.counts);
      const datasets = [
        {
          type: 'line',
          label: '累計会員数',
          data: data.map((item) => item.total),
          borderColor: pointColors.orange,
          backgroundColor: pointColors.orange,
          yAxisID: 'y-axis-left',
        },
        {
          type: 'line',
          label: '会員数',
          data: data.map((item) => item.active),
          borderColor: pointColors.green,
          backgroundColor: pointColors.green,
          yAxisID: 'y-axis-left',
        },
        {
          type: 'bar',
          label: '新規会員登録数',
          data: data.map((item) => item.new),
          borderColor: pointColors.red,
          backgroundColor: pointColors.red,
          yAxisID: 'y-axis-right',
        },
        {
          type: 'bar',
          label: 'アカウント削除(退会)数',
          data: data.map((item) => item.leave),
          backgroundColor: pointColors.blue,
          yAxisID: 'y-axis-right',
        },
      ];

      // https://www.chartjs.org/docs/3.9.1/developers/api.html
      if (changeCountsChart) {
        changeCountsChart.data.labels = labels;
        changeCountsChart.data.datasets = datasets;
        changeCountsChartHiddenIndexes.value.forEach((item) => changeCountsChart.setDatasetVisibility(item, false));
        changeCountsChart.update();
      } else {
        changeCountsChart = new Chart(changeCountsChartCanvas.value, {
          data: {
            labels: labels,
            datasets: datasets,
          },
          options: {
            plugins: {
              legend: {
                labels: { usePointStyle: true, pointStyle: 'rect' },
                onClick: function(event: any, legendItem: any, legend: any) {
                  // https://www.chartjs.org/docs/3.9.1/configuration/legend.html#configuration-options
                  const index = legendItem.datasetIndex;
                  const ci = legend.chart;
                  if (ci.isDatasetVisible(index)) {
                    ci.hide(index);
                    legendItem.hidden = true;
                    changeCountsChartHiddenIndexes.value.push(index);
                  } else {
                    ci.show(index);
                    legendItem.hidden = false;
                    changeCountsChartHiddenIndexes.value = changeCountsChartHiddenIndexes.value.filter(
                      (item) => item !== index
                    );
                  }
                },
              },
            },
            scales: {
              'y-axis-left': {
                type: 'linear',
                position: 'left',
                beginAtZero: true,
                ticks: {
                  callback: function(value: number) {
                    if (Math.floor(value) === value) return value;
                  },
                },
                title: {
                  display: true,
                  text: '会員数推移',
                },
              },
              'y-axis-right': {
                type: 'linear',
                position: 'right',
                beginAtZero: true,
                ticks: {
                  callback: function(value: number) {
                    if (Math.floor(value) === value) return value;
                  },
                },
                title: {
                  display: true,
                  text: '新規会員登録・アカウント削除(退会)数',
                },
              },
            },
          },
        });
      }
    };

    // 有料プラン会員数推移
    const subscriptionChangeFilterDates = ref<(number | null)[]>([null, null]);
    const subscriptionChangeCounts = computed(() => {
      const subscriptionCounts = report.subscriptionCounts;
      if (!subscriptionCounts.length) return null;

      const filteredData = report.getFilteredData(subscriptionCounts, subscriptionChangeFilterDates.value);
      if (!filteredData) return null;

      const filteredDataDates = filteredData.dates;
      const filteredDataItems = filteredData.items;

      return {
        displayDate: filteredDataDates,
        data: filteredDataItems,
      };
    });

    const subscriptionChangeCountsChartCanvas = ref<HTMLCanvasElement | null>(null);
    let subscriptionChangeCountsChart: any = null;
    const subscriptionChangeCountsChartHiddenIndexes = ref<number[]>([]); // 再レンダリング時に非表示にしておくデータのindexを保管
    const createSubscriptionChangeCountsChart = () => {
      if (!subscriptionChangeCountsChartCanvas.value || report.isLoading || !subscriptionChangeCounts.value) return;

      const data = subscriptionChangeCounts.value.data;
      const labels = data.map((item) => item.displayDate);
      const activeSubscriptionPlans = report.activeSubscriptionPlans;
      const datasets = activeSubscriptionPlans.map((activePlan, index) => {
        const colorIndex = index < basicColors.length ? index : index % basicColors.length;
        return {
          borderColor: basicColors[colorIndex],
          backgroundColor: basicColors[colorIndex],
          label: activePlan.subscriptionPlanName,
          data: data.reduce((acc: number[], current) => {
            const item = current.plans.filter((plan) => plan.subscriptionPlanId === activePlan.subscriptionPlanId)[0];
            acc.push(item ? item.count : 0);
            return acc;
          }, []),
        };
      });

      if (subscriptionChangeCountsChart) {
        subscriptionChangeCountsChart.data.labels = labels;
        subscriptionChangeCountsChart.data.datasets = datasets;
        subscriptionChangeCountsChartHiddenIndexes.value.forEach((item) =>
          subscriptionChangeCountsChart.setDatasetVisibility(item, false)
        );
        subscriptionChangeCountsChart.update();
      } else {
        subscriptionChangeCountsChart = new Chart(subscriptionChangeCountsChartCanvas.value, {
          type: 'line',
          data: {
            labels: labels,
            datasets: datasets,
          },
          options: {
            plugins: {
              legend: {
                labels: { usePointStyle: true, pointStyle: 'rect' },
                onClick: function(event: any, legendItem: any, legend: any) {
                  // https://www.chartjs.org/docs/3.9.1/configuration/legend.html#configuration-options
                  const index = legendItem.datasetIndex;
                  const ci = legend.chart;
                  if (ci.isDatasetVisible(index)) {
                    ci.hide(index);
                    legendItem.hidden = true;
                    subscriptionChangeCountsChartHiddenIndexes.value.push(index);
                  } else {
                    ci.show(index);
                    legendItem.hidden = false;
                    subscriptionChangeCountsChartHiddenIndexes.value = subscriptionChangeCountsChartHiddenIndexes.value.filter(
                      (item) => item !== index
                    );
                  }
                },
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function(value: number) {
                    if (Math.floor(value) === value) return value;
                  },
                },
              },
            },
          },
        });
      }
    };

    // グラフ描画
    watch(
      () => [report.isLoading, userChangeCounts.value, changeCountsChartCanvas.value],
      () => createChangeCountsChart()
    );
    watch(
      () => [report.isLoading, subscriptionChangeCounts.value, subscriptionChangeCountsChartCanvas.value],
      () => createSubscriptionChangeCountsChart()
    );

    return {
      pageTitle: '会員数詳細',
      myRoleSettings,
      isPlanService: process.env.VUE_APP_GROUP_TYPE === 'plan',
      report,
      userDetailCounts,
      userChangeFilterDates,
      userChangeCounts,
      subscriptionChangeFilterDates,
      subscriptionChangeCounts,
      changeCountsChartCanvas,
      subscriptionChangeCountsChartCanvas,
    };
  },
});
