



























































import { defineComponent, ref, PropType } from '@vue/composition-api';
import { DisplayDate } from '@/admin/util';
import { useNotification } from '@/composition/notification';

export default defineComponent({
  name: 'FcDateFilter',
  props: {
    label: {
      type: String,
      default: '絞り込み',
    },
    value: {
      type: Array as PropType<(number | null)[]>,
      default: null,
    },
  },
  setup(props, context) {
    const notification = useNotification();
    const sDate = ref('');
    const eDate = ref('');
    if (props.value[0]) {
      sDate.value = new DisplayDate(props.value[0]).date;
    }
    if (props.value[1]) {
      eDate.value = new DisplayDate(props.value[1]).date;
    }
    const formatDate = (d: string) => {
      const [YYYY, MM] = d.split('-');
      return `${YYYY}年${Number(MM)}月`;
    };
    const formatWeekday = (d: string) => {
      const date = new Date(d);
      return ['日', '月', '火', '水', '木', '金', '土'][date.getDay()];
    };
    const isActiveDialog = ref(false);
    const isCheckedAll = ref(true);

    // 選択したヘッダーを反映
    const setDisplay = () => {
      const dates: (number | null)[] = [];
      if (sDate.value && eDate.value && sDate.value > eDate.value) {
        notification.error('開始日は終了日以前の日付を設定してください');
        return;
      }
      if (sDate.value) {
        const startDateTime = new Date(`${sDate.value.replace(/-/g, '/')} 00:00:00`).getTime();
        dates.push(startDateTime);
      } else dates.push(null);
      if (eDate.value) {
        const endDateTime = new Date(`${eDate.value.replace(/-/g, '/')} 23:59:59`).getTime();
        dates.push(endDateTime);
      } else dates.push(null);
      context.emit('input', dates);
      isActiveDialog.value = false;
    };

    const cancelDialog = () => {
      isActiveDialog.value = false;
    };

    setDisplay();

    return {
      sDate,
      eDate,
      isActiveDialog,
      isCheckedAll,
      setDisplay,
      formatDate,
      formatWeekday,
      cancelDialog,
    };
  },
});
